import React, { Component } from 'react';
import Layout from '../components/organisms/Layout'
import SEO from '../components/molecules/SEO'
import MarkdownConverter from '../components/elements/MarkdownConverter';
import styled from 'styled-components';

const Holder = styled.article`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  margin-bottom: 2rem;
  border-top: 0.5px solid;
  padding-top: 2rem;
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: 2fr 10fr;
  }
  > :nth-child(even) {
    margin-bottom: 3rem;
    @media ( ${props => props.theme.breakpoints.lg} ) {
      width: 60%;
    }
  }
  > * {
    margin: 0;
    > :first-child { margin-top: 0; }
    > :last-child { margin-bottom: 0; }
  }
`;

class CV extends Component {
  render() {
    const frontmatter = this.props.data.allMarkdownRemark.edges[0].node.frontmatter;
    return (
      <Layout>
        <SEO title="CV"/>
        <Holder>
          <header>
            <h1 className="h2">Trent Whitehead CV</h1>
          </header>
          <MarkdownConverter content={frontmatter.intro}/>
          <h2>Solo Exhibitions</h2>
          <MarkdownConverter content={frontmatter.solo_exhibitions}/>
          <h2>Group Exhibitions</h2>
          <MarkdownConverter content={frontmatter.group_exhibitions}/>
          <h2>Artfairs</h2>
          <MarkdownConverter content={frontmatter.artfairs}/>
          <h2>Collections</h2>
          <MarkdownConverter content={frontmatter.collections}/>
          <h2>Publications</h2>
          <MarkdownConverter content={frontmatter.publications}/>
        </Holder>
      </Layout>
    )
  }
}

export default CV;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex : "/cv.md/"}
      }
    ){
      edges {
        node{
          frontmatter {
            intro
            solo_exhibitions
            group_exhibitions
            artfairs
            collections
            publications
          }
        }
      }
    }
  }
`